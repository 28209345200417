.userdetails_heading {
    font-size: 20px;
    margin: 0px 5%;
    margin-top: 30px;
    color: #333;

}

.userdetails {
    display: flex;
    align-items: stretch;
    max-width: 90%;
    margin: 0 auto;
    padding: 1% 3%;
    gap: 15px
}

.userdetails_up_div {
    display: flex;
    flex-direction: column;
}

.userdetails_up {
    width: 400px;

}

.userprofile_up {
    text-align: center;
    background-color: var(--red);
    color: #ffffffe2;
    font-family: Poppins;
    padding: 20px 0;

}

.userpost {
    margin-bottom: 25px;
    cursor: pointer;
}

.userprofile_up>img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: linear-gradient(var(--grey1), var(--black));
    cursor: pointer;
}

.username {
    font-size: 20px;
}

.userpro {
    font-size: 16px;
    margin-bottom: 5px;
}

.userbio {
    margin: auto;
    width: 90%;
    font-size: 14px;
    word-wrap: break-word;

}

.userprofile_down {
    background-color: #fff;
    color: #1E1E44;
    font-family: Poppins;
    padding: 5% 0;
}

.userpost>h1 {
    font-size: 27px;
}

.userdetails_down {
    width: calc(100% - 415px);
    background-color: #fff;
    color: var(--black);
    padding: 3% 0;
    font-family: Poppins;
}

.userdata {
    margin: 8px 30px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    font-family: Poppins;
    border-bottom: 1px solid #e1e1e1;
}

.userdata>span {
    font-weight: normal;
    word-wrap: break-word;
    width: 75%;
    text-align: left;
}

.userdata_img {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
}

.userdata_img>img {
    width: 150px;
    border: 2px solid var(--black);
}

.userdata_route {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    font-weight: normal;
    padding: 20px;
}


.userreports {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 12px 30px;

}

.userreports>h1 {
    font-size: 20px;
    color: darkcyan
}

.useractive_button {
    background: linear-gradient(90deg, rgba(12, 140, 76, 1) 36%, rgba(104, 152, 51, 1) 100%);
    color: white;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 10px;
    width: 180px;
    border-radius: 5px;
    cursor: pointer;
}

.userdeactive_button {
    background-color: #c42a2a;
    color: white;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 10px;
    width: 180px;
    border-radius: 5px;
    cursor: pointer;
}

.driver_heading {
    font-size: 20px;
    margin: 0;
    width: 100px
}

@media(max-width:1400px) {
    .userdetails {
        flex-direction: column;
    }

    .userdetails_up {
        width: 100%;
    }

    .userprofile_up {
        padding: 5% 0;
    }

    .userprofile_down {
        padding: 3% 0;
    }

    .userdetails_down {
        width: 100%;
    }

}