.card_list {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2%;
    padding: 0 5%;
}

.card {
    color: white;
    text-align: center;
    padding: 0 3%;
    border-radius: 5px;
    width: 95%;
    height: 100px;
    /* background: linear-gradient(to right, #6b1a896a, #7a7ab1); */
    background-color: #eb7475;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

}

/* .card:nth-child(1) {
    background: linear-gradient(to right, #90caf9, rgb(66, 113, 151) 99%)
}

.card:nth-child(2) {
    background: linear-gradient(to right, #6b1a896a, #7a7ab1);

}

.card:nth-child(3) {
    background: linear-gradient(to right, #ffbf96, #fe7096)
    background: linear-gradient(to right, #ffbf96, #c73d62)
} */

.card_data {
    text-align: right;
}


.card_data>p {
    font-size: 15px;
    margin: 5px;
    margin-bottom: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.card_icon {
    width: 32px;
    height: 32px;
    padding: 3%;
    background-color: #ed8283;
    border-radius: 50px;
}

#chart {
    background-color: white;
    border: 1px solid var(--grey);
    border-radius: 5px;
    width: 43%;
    margin: 3% 4%;
    padding: 1%;
}

.dashboard_icon {
    font-size: 30px !important;
    color: white;
    animation: 1.5s linear circular;

}

@keyframes circular {
    0% {
        scale: 0;
        rotate: 360deg;
    }

    100% {
        scale: 1;
        rotate: 0deg;

    }
}

.dashboard_counter {
    /* animation-name: count;
    animation-duration: 2s; */
    font-size: 20px;
    margin: 0 5px;


}

@keyframes count {
    0% {
        font-size: 50px;
    }

    100% {
        font-size: 20px;

    }
}

@media(max-width:1100px) {
    .card_list {
        grid-template-columns: auto auto;
        row-gap: 20px;
    }

    /* .card_data>h1 {
        font-size: 24px;

    } */
}

@media(max-width:900px) {
    #chart {
        width: 90%
    }
}

@media(max-width:650px) {
    .card_list {
        grid-template-columns: auto;
        padding: 0 4%;
    }
}