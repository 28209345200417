* {
    margin: 0;
    padding: 0;
    font-family: poppins;

}

body {
    margin: 0;
    color: var(--black) !important;
}

:root {
    --red: #eb7475;
    --errorred: red;
    --lightred: #ed8283;
    --black: #2A1A1A;
    --blurblack: #00000045;
    --white: #ffffff;
    --blurwhite: #ffffffcf;
    --yellow: #FEE24C;
    --grey: #D9D9D9;
    --grey1: #838383;
    --grey2: #F9F9F9;
    --grey3: #6F6F6F;
    --grey4: #BDBDBD;
    --grey5: #5F5F5F;
    --grey6: #f5f5f5;
    --tablegrey: #f7f7f7;
    --bordergrey: #7070701f;
}

@font-face {
    font-family: 'Nehad';
    src: url('./Fonts/Nehad.ttf') format('truetype');
}