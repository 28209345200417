.data_heading {
  font-size: 20px;
  margin: 20px;
  color: #333;
  text-align: center;

}

.container1 {
  width: 82%;
  margin: 0 auto;
  text-align: center;
  padding: 1% 3%;
  background-color: white;
  border-radius: 6px;
  border: 1px solid var(--bordergrey)
}

.data_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchicon {
  width: 230px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.205);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  margin: 5px;
  margin-bottom: 15px !important;
  float: right;

}

.searchicon>input {
  width: 100%;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 8px;
  font-size: 13px;
}

.data_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  text-transform: capitalize;
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.171); */
}

.data_table th,
.data_table td {
  padding: 11px;
  text-align: left;
  vertical-align: middle;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid var(--bordergrey)
}

.data_table th {
  background-color: var(--tablegrey);
  color: var(--black);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1px;
  /* border-bottom: 2px solid #333; */
}

.data_table td {
  background-color: #fff;
  font-size: 13px;
}

.data_table tr:hover td {
  background-color: var(--tablegrey);
}

.data_button_view {
  /* background: linear-gradient(90deg, rgba(12, 140, 76, 1) 36%, rgba(104, 152, 51, 1) 100%); */
  background: var(--red);
  padding: 5px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.download_button_view {
  /* background: linear-gradient(90deg, rgba(12, 140, 76, 1) 36%, rgba(104, 152, 51, 1) 100%); */
  background: var(--red);
  padding: 5px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px
}

.pending_data {
  border: 1px solid #f6b84a;
  color: #f6b84a;
  padding: 1px 5px;
  text-wrap: nowrap;
  border-radius: 50px;

}

.upcoming_data {
  border: 1px solid #52abce;
  color: #52abce;
  padding: 1px 5px;
  text-wrap: nowrap;
  border-radius: 50px;

}

.start_data {
  border: 1px solid #a764e2;
  color: #a764e2;
  padding: 1px 5px;
  text-wrap: nowrap;
  border-radius: 50px;


}

.cancel_data {
  border: 1px solid #d51010;
  color: #d51010;
  padding: 1px 5px;
  text-wrap: nowrap;
  border-radius: 50px;


}

.complete_data {
  border: 1px solid #3ba910;
  color: #3ba910;
  padding: 1px 5px;
  text-wrap: nowrap;
  border-radius: 50px;

}

.yes_data {
  color: blue;
  border: 1px solid blue;
  padding: 2px 10px;
  border-radius: 5px;

}

.no_data {
  color: orange;
  border: 1px solid orange;
  padding: 2px 10px;
  border-radius: 5px;



}

.selectfield{
  width: 130px;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 8px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.205);
  border-radius: 4px;
}
.selectfieldOrder{
  width: 150px;
  height: 39px;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 8px;
  font-size: 13px;
  /* border: 1px solid rgba(0, 0, 0, 0.205); */
  border-radius: 4px;
}

.filter_main{
  display: flex;
  align-items: center;
  gap: 5px;
}



.order_profile_img{
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #fff;
  overflow: hidden;
}
.order_profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire div without stretching */
}

.data_heading_order {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
  color: #333;
  text-align: center;

}


@media(max-width:1250px) {
  .container1 {
    width: 87%;
  }

}

@media(max-width:950px) {
  .data_heading {
    font-size: 18px;

  }

  .searchicon {
    padding: 0px 10px;
  }

  .searchicon>input {
    height: 25px;
  }

  .container1 {
    width: 90%;
  }

  .data_table th {
    font-size: 13px;
    padding: 10px;
  }

}

@media(max-width:600px) {
  .data_head {
    flex-direction: column;
  }

  .data_heading {
    font-size: 16px;

  }

  .data_userdetails {
    flex-direction: column;
    align-items: normal;
    text-align: center;
  }

  .container1 {
    width: 90%;

  }

  .data_table_over {
    overflow-x: scroll;

  }

  .data_table th {
    font-size: 12px;
    padding: 10px;
  }

  .pagination_activebutton,
  .pagination_button {
    font-size: 12px;

  }
}