.pagination {
    display: flex;
    justify-content: space-between;
}

.pagination>p {
    font-size: 14px;
    font-weight: 500;
}

.paginationbtns {
    display: flex;
    justify-content: flex-end;
    gap: 10px
}

.pagination_button {
    padding: 5px;
    background-color: #e9e9e9;
    border: 1px solid #ddd;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.pagination_button:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.514)
}

.pagination_activebutton {
    padding: 8px 12px;
    width: 35px;
    height: 35px;
    background: linear-gradient(180deg, #ec1e26 0%, #d32f2f 100%);
    /* background: linear-gradient(90deg, rgba(60, 60, 119) 40%, #9a36e1e1 100%); */
    border-radius: 5px;
    color: white;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
}