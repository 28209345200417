.Sidebar {
    flex-direction: column;
    width: 250px;
    z-index: 0;

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        display: block;
    }
}

.sidebar_logo {
    margin: 10% 20%;
    width: 140px;
}

.SidebarItem {
    list-style-type: none;
}

.SidebarItem>a {
    display: flex;
    gap: 15px;
    align-items: center;
    color: var(--grey5);
    margin: 5px 10px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        border-radius: 5px;
        color: var(--black);
    }
}

.SidebarItem>div {
    display: flex;
    gap: 15px;
    align-items: center;
    color: var(--grey5);
    margin: 5px 10px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
}

.sidebarlabel {
    font-size: 14px;
    font-family: poppins;
}

.appbar {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 250px);
    margin-left: 250px;
    background-color: #f0f1f4;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    gap: 25px;
    z-index: 1;
}

.appbarprofileimg {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid var(--grey);
    cursor: pointer;
}

@media(max-width:900px) {
    .appbar {
        width: calc(100% - 20px);
        justify-content: space-between
    }
}